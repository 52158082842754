import {Request} from "@/Request";

class Wage extends Request {
    
    public path: string = '/wage';
    
    certain = (data: any) => {
        return this.formPost(`${this.path}/certain`, data)
    }

    sendMsg = (data: any) => {
        return this.formPost(`${this.path}/sendMsg`, data)
    }

    getWageMonth = (data: any) => {
        return this.formPost(`${this.path}/getWageMonth`, data)
    }

    recall = (data: any) => {
        return this.formPost(`${this.path}/recall`, data)
    }

    del = (id: string) => {
        return this.formPost(`${this.path}/${id}`)
    }
}

export default new Wage();