
import {computed, createVNode, defineComponent, h, reactive, ref, resolveComponent, toRefs, UnwrapRef} from 'vue';
import {message, Modal} from "ant-design-vue";
import {ExclamationCircleOutlined, SendOutlined, UploadOutlined} from "@ant-design/icons-vue";
import Wage from "@/request/Wage";
import SessionStorageKeys from "@/common/SessionStorageKeys";
import ElTable from "@/components/el-table.vue";
import {ColumnProps} from 'ant-design-vue/es/table/interface';

type Key = ColumnProps['key'];

interface FileItem {
    uid: string;
    name?: string;
    status?: string;
    response?: Response;
    url: string;
}

interface FileInfo {
    file: FileItem;
    fileList: FileItem[];
}

const companyId = ref();
const isAdmin = ref<boolean>(false)

export default defineComponent({
    components: {
        ElTable,
        UploadOutlined,
        SendOutlined,
    },
    beforeCreate() {
        isAdmin.value = sessionStorage.getItem(SessionStorageKeys.type) === 'admin'
        companyId.value = isAdmin.value ? '' : sessionStorage.getItem(SessionStorageKeys.companyId)
    },
    setup() {
        const path = ref(Wage.path);
        const table = ref();
        const formRef = ref();
        const selectedCompany = ref<string[]>()

        const params = reactive({
            companyId: companyId,
            month: '',
            id: null,
            recall: 0,
            name: '',
            ids: '',
            state: null,
            taxState: null
        })

        const onCompanyChange = (data: any) => {
            params.companyId = data.item.id
            upData.companyId = params.companyId
            formData.companyId = params.companyId
            sendParams.companyId = params.companyId
            companyId.value = params.companyId
            getWageMonth()
            state.selectedRowKeys = [];
            table.value.flush()
        }

        const wageMonth = ref<any>()
        const showMonth = ref<boolean>(false)
        const getWageMonth = () => {
            Wage.getWageMonth(params).then((body: any) => {
                body.data ? wageMonth.value = [body.data] : wageMonth.value = null;
                if (wageMonth.value) wageMonth.value.length > 0 ? showMonth.value = true : showMonth.value = false;
                else showMonth.value = false
            })
        }

        const columns2 = ref<any>();

        const formData: UnwrapRef<any> = reactive({
            month: '',
            type: '',
            companyId: companyId
        });

        const sendParams: UnwrapRef<any> = reactive({
            id: '',
            companyId: companyId,
            needSign: 0,
            sendType: [],
        })

        const current = ref<number>(0);

        const jump = () => {
            current.value++;
            waiting.value= true
            certain()
        };

        const next = () => {
            current.value++;
        };

        const prev = () => {
            current.value--;
        };

        const visible = ref<boolean>(false);

        const detailShow = ref<boolean>(false);

        const waiting = ref<boolean>(false);

        const showDrawer = () => {
            current.value = 0
            visible.value = true;
            formData.month = ''
            formData.type = ''
            isConfirm.value = true
        };

        const onClose = () => {
            visible.value = false;
            current.value = 0
        };

        const edit = (data: any) => {
            visible.value = true;
            formData.id = data.id;
            formData.name = data.name;
            formData.code = data.code;
            formData.address = data.address;
            formData.scope = data.scope;
            formData.linkMan = data.linkMan;
            formData.phone = data.phone
        }

        const onSubmit = () => {
            formRef.value
                .validate()
                .then(() => {
                    Wage.save(formData).then((body: any) => {
                        if (body.code === 0) {
                            visible.value = false;
                            formRef.value.resetFields();
                            message.info("保存成功！")
                        } else {
                            message.error(body.msg);
                        }
                    });
                })
        };

        const msg = ref<string>('');

        const uploadData = ref<any>();

        const getByMonth = (data: any) => {
            params.month = data.key
            table.value.flush(1)
        }

        const handleChange = (info: FileInfo) => {
            waiting.value = true
            let resFileList = [...info.fileList];

            resFileList = resFileList.slice(-2);

            resFileList.map(file => {
                waiting.value = false;
                if (file.response) {
                    if (file.response.code == 0) {
                        message.info('上传成功！')
                        current.value ++;
                        columns2.value = [{
                            title: '关联',
                            dataIndex: 'match',
                            align: 'center',
                            customRender: ({record}: any) => {
                                return h(
                                    'div',
                                    {},
                                    [
                                        h(resolveComponent('a-tag'), {
                                            color: (record.match == 1 ? 'green' : record.match == 2 ? 'red' : ''),
                                        }, (record.match == 1 ? '匹配成功' : record.match == 2 ? '匹配失败' : ''))
                                    ]
                                )
                            }
                        }]
                        if (file.response.data.titles) {
                            const titles = file.response.data.titles;
                            for (let i = 0; i < titles.length; i++) {
                                columns2.value.push({
                                    title: titles[i].title,
                                    width: Number(titles[i].width),
                                    dataIndex: titles[i].dataIndex
                                })
                            }
                        }
                        uploadData.value = file.response.data.data
                        msg.value = file.response.msg
                    } else {
                        message.error(file.response.msg)
                    }
                }
                return file;
            });

        };

        const isConfirm = ref<boolean>(true);

        const check = () => {
            if (formData.type && formData.month) isConfirm.value = false
        }

        const wages = ref<any>([]);
        const selectedWage = ref<string[]>()

        const upData: UnwrapRef<any> = reactive({
            companyId: companyId
        });

        const wage = ref<any>()

        const state = reactive<{
            selectedRowKeys: Key[];
        }>({
            selectedRowKeys: [] // Check here to configure the default column
        });
        const hasSelected = computed(() => state.selectedRowKeys.length > 0);

        const onSelectChange = (selectedRowKeys: Key[]) => {
            state.selectedRowKeys = selectedRowKeys;
        };

        const recall = () => {
            if (state.selectedRowKeys.length > 0) {
                params.recall = 1;
                params.ids = state.selectedRowKeys.toString();
                Modal.confirm({
                    title: '确定要撤回吗?',
                    icon: createVNode(ExclamationCircleOutlined),
                    okText: '确定',
                    okType: ('danger'),
                    cancelText: '取消',
                    onOk() {
                        Wage.recall(params).then((body: any) => {
                            if (body.code === 0) {
                                table.value.flush(1)
                                state.selectedRowKeys = []
                                message.info(body.msg)
                            }
                        })
                    }
                });

            } else {
                message.info('请先选择需要撤回的内容！')
            }
        }

        const choose = (data: any) => {
            for (let i = 0; i < wages.value.length; i++) {
                if (data.key === wages.value[i].id) {
                    wage.value = wages.value[i]
                    sendParams.id = data.key
                    wage.value.extend = JSON.parse(wage.value.extend)
                    wage.value.extendTax = JSON.parse(wage.value.extendTax)
                }
            }
        }

        const closeUpload = () => {
            visible.value = false
            table.value.flush();
        }

        const certain = () => {
            waiting.value = true
            Wage.certain(upData).then((body: any) => {
                waiting.value = false
                if (body.code == 0) {
                    if (current.value !== 4) current.value ++;
                    wages.value = body.data
                    selectedWage.value = [wages.value[0].id]
                    sendParams.id = wages.value[0].id;
                    wage.value = wages.value[0]
                    wage.value.extend = JSON.parse(wage.value.extend)
                    wage.value.extendTax = JSON.parse(wage.value.extendTax)
                    message.success('保存成功!')
                }
            });
        };

        const sendMsg = () => {
            Wage.sendMsg(sendParams).then((body: any) => {
                if (body.code == 0) {
                    message.info(body.msg)
                    wage.value.state = 1
                }
            })
        }

        const detail = ({data}: any) => {
            console.log('--->', data)
        }

        const download = (type: number) => {
            let dom = document.createElement('a'); // 创建a标签
            if (type === 1) {
                dom.setAttribute('download', '工资表导入模板');// download属性
                dom.setAttribute('href', `${process.env.VUE_APP_BASE_URL}` + '/工资表导入模板.xls');// href链接
            } else if (type === 2) {
                dom.setAttribute('download', '个税表导入模板');// download属性
                dom.setAttribute('href', `${process.env.VUE_APP_BASE_URL}` + '/个税表导入模板.xls');// href链接
            }
            dom.click()

            waiting.value = true
            setTimeout(() => {
                waiting.value = false
            }, 1000)
        };

        return {
            showCompanyList: ref<boolean>(false),
            columns2,
            path,
            upData,
            jump,
            wages,
            isConfirm,
            selectedWage,
            check,
            closeUpload,
            headers: ref({
                token: sessionStorage.getItem(SessionStorageKeys.token)
            }),
            uploadUrl: ref<string>(`${process.env.VUE_APP_BASE_URL}` + Wage.path + `/upload`),
            handleChange,
            formData,
            choose,
            wage,
            download,
            onSubmit,
            visible,
            uploadData,
            showDrawer,
            onClose,
            formRef,
            waiting,
            edit,
            current,
            next,
            certain,
            prev,
            table,
            selectedCompany,
            onCompanyChange,
            sendMsg,
            isAdmin,
            params,
            wageMonth,
            showMonth,
            sendParams,
            getByMonth,
            recall,
            detailShow,
            hasSelected,
            onSelectChange,
            ...toRefs(state),
            actions: ref<Actions[]>([
                {
                    name: '查看详情',
                    event: 'edit'
                },
                {
                    name: '撤回',
                    event: 'del'
                }
            ]),
            steps: ref([
                {
                    title: '上传工资表',
                    content: '上传日常使用的Excel工资表'
                },
                {
                    title: '核对工资',
                    content: '核对工资',
                },
                {
                    title: '上传个税详情',
                    content: '上传日常使用的Excel个税详情'
                },
                {
                    title: '核对个税详情',
                    content: '核对个税详情',
                },
                {
                    title: '预览并发送',
                    content: '预览并发送',
                }]
            ),
            detail,
            search: ref([
                {
                    tag: 'input',
                    label: '姓名',
                    key: 'name'
                },
                {
                    tag: 'select',
                    label: '工资条状态',
                    key: 'state',
                    width: '100px',
                    options: [
                        {
                            key: '0',
                            value: '未读'
                        },
                        {
                            key: '1',
                            value: '已读'
                        },
                        {
                            key: '2',
                            value: '发送失败'
                        }
                    ]
                },
                {
                    tag: 'select',
                    label: '个税详情状态',
                    key: 'taxState',
                    width: '100px',
                    options: [
                        {
                            key: '0',
                            value: '未读'
                        },
                        {
                            key: '1',
                            value: '已读'
                        },
                        {
                            key: '2',
                            value: '有反馈'
                        }
                    ]
                },
            ]),
            rules: {
                type: [{required: true, message: '请选择月份', type: 'string'}],
                month: [{required: true, message: '请输入姓名', type: 'string'}]
            },
            columns: ref([
                {
                    dataIndex: 'date',
                    title: '发薪时间'
                },
                {
                    dataIndex: 'type',
                    title: '发薪类型'
                },
                {
                    dataIndex: 'name',
                    title: '姓名'
                },
                {
                    dataIndex: 'idCard',
                    title: '证照号码'
                },
                {
                    dataIndex: 'amount',
                    title: '实发'
                },
                {
                    dataIndex: 'tax',
                    title: '个人所得税'
                },
                {
                    dataIndex: 'fee',
                    title: '应发'
                },
                {
                    dataIndex: 'state',
                    title: '工资条状态', //1：已发送/未读，2：发送失败，3：已读
                    customRender: ({record}: any) => {
                        return h(
                            'div',
                            {},
                            [
                                h(resolveComponent('a-tag'), {
                                    color: (record.state == 1 ? 'green' : record.state == 0 ? 'default' : 'default'),
                                }, (record.state == 1 ? '已发送/未读' : record.state == 2 ? '发送失败' : record.state == 3 ? '已读' : record.state == 0 ? '未发送' : '未发送'))
                            ]
                        );
                    }
                },
                {
                    dataIndex: 'taxState',
                    title: '个税信息状态',
                    customRender: ({record}: any) => {
                        return h(
                            'div',
                            {},
                            [
                                h(resolveComponent('a-tag'), {
                                    color: (record.taxState === 1 ? 'green' : record.taxState == 0 ? 'default' : record.taxState === 2 ? 'orange' : 'default'),
                                }, (record.taxState === 1 ? '已读' : record.taxState === 0 ? '未读' : record.taxState === 2 ? '有反馈' : '未读'))
                            ]
                        );
                    }
                },
                {
                    title: '操作',
                    align: 'center',
                    key: 'action',
                    fixed: 'right',
                    width: 220,
                    customRender: ({record}: any) => {
                        return h(
                            'div',
                            {},
                            [
                                h('a', {
                                        style: 'margin-right: 10px;' + (record.recall === 1 ? 'pointer-events: none;filter: alpha(opacity=50); -moz-opacity: 0.5; opacity: 0.5;' : ''),
                                        onClick() {
                                            detailShow.value = true;
                                            record.extendStr ? record.extend = JSON.parse(record.extendStr) : ''
                                            record.extendTaxStr ? record.extendTax = JSON.parse(record.extendTaxStr) : ''
                                            wage.value = record
                                        }
                                    },
                                    '查看详情'),
                                h('a', {
                                    style: 'margin-right: 10px;',
                                    onClick() {
                                        Modal.confirm({
                                            title: '确定要' + ((!record.state || record.state == 0) ? '发送' : record.recall === 1 ? '重新发送' : '撤回') + '吗?',
                                            icon: createVNode(ExclamationCircleOutlined),
                                            okText: '确定',
                                            okType: ((!record.state || record.state == 0) ? 'default' : record.recall === 1 ? 'default' : 'danger'),
                                            cancelText: '取消',
                                            onOk() {
                                                if (!record.recall || record.recall === 0) {
                                                    if (!record.state || record.state == 0) params.recall = 0;
                                                    else params.recall = 1;
                                                } else {
                                                    params.recall = 0
                                                }
                                                params.ids = record.id
                                                params.month = record.date
                                                Wage.recall(params).then((body: any) => {
                                                    if (body.code === 0) {
                                                        message.info('操作成功！')
                                                        table.value.flush(1)
                                                    }
                                                })
                                            }
                                        });

                                    }
                                }, (!record.state || record.state == 0) ? '发送' : record.recall === 1 ? '重新发送' : '撤回'),
                                h('a', {
                                    onClick() {
                                        Modal.confirm({
                                            title: '确定要删除吗?',
                                            icon: createVNode(ExclamationCircleOutlined),
                                            okText: '确定',
                                            okType: 'danger',
                                            cancelText: '取消',
                                            onOk() {
                                                Wage.del(record.id).then((body: any) => {
                                                    if (body.code === 0) {
                                                        state.selectedRowKeys = [];
                                                        message.info(body.msg);
                                                        table.value.flush(1)
                                                    }
                                                })
                                            }
                                        });

                                    }
                                }, record.recall === 1 ? '删除' : ''),

                            ]
                        )
                    }
                }
            ]),
        }
    }
})
